<template>
	<div
		:class="[
			'tile-selectable-base',
			'p-3',
			model.backgroundColor?.cssClass,
			{ select: state.selected },
			{ 'show-multiple multiple-container': model.selectMultiple },
			{ disabledCard: state.disabled },
			model.bmetricId
		]"
		@click="toggleSelect"
	>
		<div :class="[{ selected: state.selected }]">
			<div
				v-if="model.selectMultiple"
				:class="['amount', { amount__show: state.multipleCount > 0 }]"
			>
				<span
					class="txt"
					v-text="state.multipleCount"
				/>
			</div>
			<div v-if="model.selectMultiple">
				<div class="add-remove">
					<div
						id="productPlus"
						:class="['add-remove plus', { disabled: state.multipleCount >= model.selectMultipleMax }]"
						@click="add"
					/>
					<div
						id="productMinus"
						:class="['add-remove minus', { disabled: state.multipleCount < 1 }]"
						@click="remove"
					/>
				</div>
			</div>

			<lazy-image
				v-if="model.icon && !storybook"
				:image="model.icon"
				fixedHeight="uspSmall"
			/>
			<!-- For storybook (storybook cannot render lazy images) -->
			<picture v-if="storybook">
				<img
					class="tile-base__imgSize"
					alt=""
					:src="model.icon?.url"
				/>
			</picture>

			<h4
				v-if="model.title"
				:class="['rich-text', 'text-center', 'mt-1']"
			>
				{{ model.title }}
			</h4>
			<!-- box--small -->
			<div class="selectable mt-1">
				<div :class="['checkmark-container ', { select: state.selected }]">
					<div :class="['checkmark', 'draw', { select: state.selected }]"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { onMounted, reactive } from 'vue';
	import { ITileSelectableBlock } from '@/types/block';
	import { SelectableContainerStore, SELECTABLE_CONTAINER_STORE_ID } from '@/stores/SelectableContainer';
	import {
		TrackProductItem,
		dataLayer,
		getTrackCategory2,
		getTrackCategory3,
	} from '@/components/utilities/trackingUtility';

	const selectableStore = SelectableContainerStore();

	const props = defineProps<{
		model: ITileSelectableBlock;
		storybook?: boolean;
	}>();

	const state = reactive({
		selected: false,
		disabled: false,
		multipleCount: 0,
	});

	if (props.model.disabledWhen) {
		// subscript to store updates, when this Tile has dependencies to others
		selectableStore.$subscribe((mutation: any) => {
			if (mutation.storeId === SELECTABLE_CONTAINER_STORE_ID) {
				let foundDisabled = false;
				const disabledWhen: Array<string> = props.model.disabledWhen.split(',');
				const currentProducts = selectableStore.selectableContainers.get(props.model.selectGroup) || [];

				disabledWhen.forEach((element) => {
					const prods = currentProducts.filter((prod) => prod === element.trim());
					if (prods.length > 0) {
						foundDisabled = true;
					}
				});
				state.disabled = foundDisabled;
			}
		});
	}

	onMounted(() => {
		state.selected = props.model.selectActive;
		if (!selectableStore.tracked) {
			selectableStore.tracked = true;
			trackViewCard();
		}
	});

	const toggleSelect = (evt: any) => {
		if (state.disabled) {
			return;
		}
		if (props.model.selectMultiple) {
			if (evt.target?.id === 'productPlus' || evt.target?.id === 'productMinus') {
				return;
			} else {
				add();
			}
		} else {
			state.selected = !state.selected;
			state.selected
				? selectableStore.add(props.model.selectGroup, props.model.selectId)
				: selectableStore.remove(props.model.selectGroup, props.model.selectId);
			if (state.selected) {
				trackAddProduct();
			}
		}
	};

	const add = () => {
		if (state.disabled) {
			return;
		}

		if (state.multipleCount < props.model.selectMultipleMax) {
			state.multipleCount++;
		} else {
			return;
		}
		state.selected = state.multipleCount > 0;

		selectableStore.add(props.model.selectGroup, props.model.selectId);
		trackAddProduct();
	};

	const remove = () => {
		if (state.disabled) {
			return;
		}
		if (state.multipleCount > 0) {
			state.multipleCount--;
		}

		state.selected = state.multipleCount > 0;
		selectableStore.remove(props.model.selectGroup, props.model.selectId);
		//trackAddRemoveProduct(props.model.selectId);
	};

	const trackAddProduct = (): void => {
		try {
			const items: TrackProductItem[] = [];
			items.push({
				item_id: props.model.selectId,
				item_name: props.model.selectId,
				affiliation: 'online',
				coupon: 'one insurance - 15% discount',
				discount: 0,
				item_brand: 'alm. brand',
				item_category: 'insurance',
				item_category2: getTrackCategory2(props.model.selectId),
				item_category3: getTrackCategory3(props.model.selectId),
				item_variant: 'basic coverage',
				price: 0,
				quantity: 1,
			});

			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'select_item',
				ecommerce: {
					item_list_id: 'basket - ab.dk',
					item_list_name: 'basket - ab.dk',
					currency: 'DKK',
					value: 0,
					items,
				},
			});
		} catch (e) {
			// too bad
			console.error(e);
		}
	};

	const trackViewCard = () => {
		try {
			const items: TrackProductItem[] = [];
			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'view_cart',
				ecommerce: {
					currency: 'DKK',
					value: 0,
					items,
				},
			});
		} catch (error) {
			console.error(error);
		}
	};
</script>

<style lang="scss" scoped>
	@import './TileSelectableBlock.module.scss';
</style>
